import React from 'react';
import { useTranslation } from 'react-i18next';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

// import sd from 'sitedata/dictionary.json';

const TestSiteDataPage = ({ data }) => {
  const { t } = useTranslation();
  const {
    cover: {
      childImageSharp: { small: coverFluid }
    },
    logo: { publicURL: logoUrl }
  } = data;

  return (
    <div style={{ padding: 20 }}>
      <div>
        <h3>i18next</h3>
        <div>
          {t('common.login')} {t('common.logout')}
        </div>
      </div>
      <div>
        <h1>PREFERRED WAY</h1>
        <h3>Page Query graphql data</h3>
        <div>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </div>
        <img src={logoUrl} alt="logo" style={{ marginRight: '30px' }} />
        <Img fluid={coverFluid} />
      </div>
    </div>
  );
};

export default TestSiteDataPage;

export const pageQuery = graphql`
  query TestSiteData {
    cover: file(name: { eq: "cover" }, sourceInstanceName: { eq: "sitedataAssets" }) {
      name
      publicURL
      childImageSharp {
        small: fluid(maxWidth: 292, maxHeight: 292) {
          src
          srcSet
          aspectRatio
          sizes
          tracedSVG
        }
      }
    }
    logo: file(name: { eq: "logo" }, sourceInstanceName: { eq: "sitedataAssets" }) {
      name
      publicURL
    }
  }
`;
